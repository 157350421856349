.titlebar {
  margin-top: 80px;
  h2 {
    margin-left: 10%;
    position: relative;
  }
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.maincontent{
  max-width: 1600px;
  min-height: calc(100vh - 200px);
  margin: 60px auto 160px;
  display: flex;
  justify-content: center;
}
.section {
  padding: 120px 100px;
  display: flex;
  justify-content: center;
}
.textblock {
    max-width: 400px;
}
.contactdetails{
  margin-top: 40px;

  p {
    margin: 10px 15px;
    font-weight: 500;
  }
}
.address {
  margin: 20px 15px;
  p {
    margin: 4px 0;
  }
}
.bgimage {
  width: 800px;
  min-width: 560px;
  width: auto;
}

@media only screen and (max-width: 1200px) {
  .maincontent {
    margin-bottom: 0;
  }
  .section {
    padding-left: 10px;
    padding-right: 10px;
  }
  .textblock {
    margin-left: 10%;
  }
}
@media only screen and (max-width: 768px) {
  .section {
    text-align: center;
    padding: 10px;
    flex-direction: column;
    max-width: 100%;
  }
  .textblock {
    margin: 0 auto;
  }
  .bgimage {
    width: auto;
    min-width: 50px;
  }
}
@media only screen and (max-width: 440px) {
  .section {
    padding: 10px;
    flex-direction: column;
  }
  .textblock {
    margin: 0;
  }
  .bgimage {
    width: auto;
    min-width: 50px;
  }
  .maincontent {
    margin-top: 0;
  }
}