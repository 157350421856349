.bannerimg {
  animation: forwards fadein 2s;
  background: url('../Views/images/wavebg.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  img {
    margin: 0 auto;
  }
}
h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #203041;
}
.galleryfull {
  display: flex;
  max-width: 1200px;
  margin: 60px auto 0;
}
.gallerytitle {
	margin-left: 18%;
}
.gallery {
	margin-top: 30px;
}
.minigallery {
  display: flex;

  img {
    object-fit: cover;
    max-width: 140px;
    margin-right: 5px;
  }
  img:hover {
    opacity: .4;
  }
}
.mainimage {
  height: 350px;
  width: 500px;
  object-fit: contain;
  object-position: left;
}
.detailimg {
  height: auto;
  object-fit: cover;
}
.galleryleft {
  width: 50%;
}
.galleryright {
  margin-top: 40px;
  margin-left: 120px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 18px;
    color: #333;
  }
}
.divider {
  margin: 100px auto;
  height: 1px;
  background: #ddd;
  width: 50%;
  max-width: 600px;
}
.btncontainer {
  margin-top: 35px;
}
.contactbtn {
  font-family: "segoe ui";
  background-color: #2d72cc;
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 12px 24px;
  margin: 30px 5px;
  font-weight: 500;
}
.madein {
  margin-top: 35px;
}
.section {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto 150px;
}
.aboutsection {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin: 120px auto;
  max-width: 1600px;
  gap: 80px;
}
.colleft{
	text-align: right;
  flex: 1 0;
}
.colright{
	text-align: left;
  flex: 1 0;
}
//// banners blocks
.banner{
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
  background-image: url('../Views/images/ripple_pattern.png');
  background-color: #e6f3ff;
  background-repeat: no-repeat;
  background-position: right;
}
.bannerblocks{
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 140px 0;
}
.bannerblock{
	margin: 0 2%;
	width: 20%;
  min-height: 100%;
  background: white;
  border-radius: 22px;
  box-shadow: 0px 5px 15px 0px #0241;
}
.bannertext{
  padding: 20px 25px;
}
.detailimg{
  max-height: 50%;
  width: 80%;
  margin: 0 auto;
  padding-top: 35px;
  object-fit: cover;
}
.aboutspec {
  margin-bottom: 35px;
}

.footerimg{
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	display: block;
	text-align: center;
}

@media only screen and (max-width: 768px) {
  .bannerimg {
    animation: forwards fadein 2s;
  
    img {
      margin: 0 auto;
      height: 370px;
    }
  }
  .galleryfull {
    display: block;
  }
  .gallery {
    width: 100%;
  }
  .galleryright, .galleryleft {
    text-align: center;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
  }
  .minigallery {
    display: block;
    width: 100%;
    margin: 8px 0 24px;

    img {
      width: 24%;
      display: inline;
    }
  }
  .mainimage{
    width: 100%;
    height: auto;
  }
  .divider {
    margin: 20px auto;
    width: 80%;
    max-width: 100%;
  }
  .section {
    margin: 0 auto 50px;
    padding: 0 16px;
    text-align: center;

    li {
      text-align: left;
      font-size: 1rem;
    }
  }
  .bannerblocks {
    display: block;
    margin: 40px 16px;

    .bannerblock {
      width: 100%;
      margin: 0 auto 20px;
      padding: 0 10px;
    }
  }
  .aboutsection {
    flex-direction: column;
    margin: 60px 16px;
    gap: 80px;

    .colleft {
      text-align: center;
    }
    .colright {
      margin: 0 20px;
    }
  }
}