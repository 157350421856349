@import url('https://fonts.googleapis.com/css?family=Pontano+Sans');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p, li {
  line-height: 1.5;
  font-size: 18px;
  margin: 20px 0;
}

.page {
  max-width: 1400px;
  margin: 0 auto;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 28px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
}

h4 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}