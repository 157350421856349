.titlebar {
    margin-top: 80px;
    h2 {
        margin-left: 10%;
        position: relative;
    }
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.maincontent{
    max-width: 1600px;
    min-height: calc(100vh - 200px);
    margin: 30px auto 160px;
    display: flex;
    justify-content: center;
}
.gallery{
    margin-top: 100px;
}
.ul {
    padding-left: 35px;
}
.li {
    list-style-type: circle;
}
button {
    font-family: "segoe ui";
    background-color: #2d72cc;
    color: white;
    border-radius: 5px;
    border: 0;
    padding: 12px 24px;
    font-weight: 500;
    margin-top: 32px;
}
.dropdown {
    padding: 20px 60px;

    p {
        padding: 0;
        margin: 10px 0;
    }

    .price {
        color: #3061A1;
        font-weight: 500;
    }
}
.mainimage {
    height: 320px;
    width: 400px;
    object-fit: contain;
    object-position: left;
}
.minigallery{
    display: block;
}
.minipic{
    width: 100px;
    display: inline-block;
}
.minipic:hover{
    opacity: .5;
}
.shopblock{
    display: inline-block;
    margin-left: 80px;
    width: 50%;
    vertical-align: top;
}
.shopselection{
    border: 1px solid lightgray;
    max-width: 100%;
}
.textblock{
    border-top: 1px solid lightgray;
    padding: 40px;
    margin-top: 10px;
}
.pdflink {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 25px;
    margin-top: 24px;
}
.pdficon {
    height: 30px;
    width: 30px;
    margin-right: 9px;
}

@media only screen and (max-width: 768px) {
    .shopblock{
        padding: 0 16px;
        margin: 0;
        width: 100%;
    }
    .gallery{
        margin: 10px;
    }
    .minipic{
        width: 24%;
    }
    .producttitle h2{
        font-size: 22px;
    }
    .producttitle h3{
        font-size: 16px;
    }
    .maincontent {
        flex-direction: column;
    }
    .titlebar {
        margin: 0;
        border: 0;
        padding: 16px 0;
        text-align: center;

        h2 {
            margin: 0;
        }
    }
}