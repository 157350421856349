.navbar {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
}
.links {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.link {
  margin: 0 12px;
  color: white;
  font-size: 18px;
  width: 130px;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  border: 3px solid #0000;
  color: #111;
}
.link:hover {
  color: #28e0e2;
  border-bottom: 3px solid #28e0e2;
}
.scrolled {
  background: #e6f3ff;
  transition: background-color .5s;
}
.hamburger {
  visibility: hidden;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  max-width: 80%;
  z-index: 99;
  transition: transform .3s;

  .window {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    transform: translateX(-100vw);
    transition: transform .3s;

    .closeButton {
      align-self: flex-end;
      text-align: right;
      top: 0;
      right: 0;
      padding: 12px 16px;
    }
  }

  ul {
    padding: 0 24px;
    padding-top: 16px;
  }

  a {
    display: block;
    text-align: left;
    border: none;
    border-top: 1px solid #ccc;
    width: 100%;
    margin: 0;
    outline: 0;
    padding: 16px 10px;
    
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
}
.draweropened {
  visibility: visible;
  transform: translateX(0) !important;
}
.opacityMask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.8);
  transition: opacity .3s;
  z-index: -1;
}

@media only screen and (max-width: 440px) {
  .navbar {
    visibility: hidden;
  }
  .hamburger {
    visibility: visible;
  }
}