.site_footer {
  background-color: #2e3036;
  color: rgb(155, 155, 155);
  font-weight: 400;
  font-family: verdana, sans-serif;
  
  li {
    font-size: .85rem;
    margin: 12px 0;
    list-style: none;
  }
}
h5 {
  color: white;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 700;
  letter-spacing: .0625em;
  margin: 15px 0
}